import React from 'react';

function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="#FFC107"
        d="M14.537 6.695H14v-.028H8v2.667h3.768A3.998 3.998 0 014 8.001a4 4 0 014-4c1.02 0 1.947.384 2.654 1.013l1.886-1.886A6.636 6.636 0 008 1.334a6.667 6.667 0 106.537 5.361z"
      />
      <path
        fill="#FF3D00"
        d="M2.102 4.898l2.19 1.606A3.998 3.998 0 018 4.001c1.02 0 1.947.384 2.654 1.013l1.885-1.886A6.636 6.636 0 008 1.334a6.663 6.663 0 00-5.898 3.564z"
      />
      <path
        fill="#4CAF50"
        d="M8 14.667a6.635 6.635 0 004.47-1.73l-2.064-1.747A3.97 3.97 0 018 12a3.998 3.998 0 01-3.761-2.648l-2.174 1.675A6.661 6.661 0 008 14.667z"
      />
      <path
        fill="#1976D2"
        d="M14.537 6.694H14v-.028H8v2.667h3.768a4.014 4.014 0 01-1.363 1.857l.001-.001 2.064 1.746c-.146.133 2.197-1.602 2.197-4.936a6.71 6.71 0 00-.13-1.305z"
      />
    </svg>
  );
}

export default Icon;
