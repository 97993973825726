import { NavigationRoutes } from 'common/routes';
import { StackNavigation } from 'hooks/use-kurosim-navigation';

import { RegistrationFormType as CompleteProfileFormType } from './form-type';

export function getRegisterDefaultValues(
  values?: Partial<CompleteProfileFormType>,
): CompleteProfileFormType {
  return {
    password: values?.password || '',
    phoneNumber: values?.phoneNumber || '',
    isPasswordStrong: values?.isPasswordStrong || false,
    referralCode: values?.referralCode ?? null,
    //background
    email: values?.email || '',
    passwordConfirmation: values?.passwordConfirmation || '',
  };
}

export function popOutOfLoginFlow() {
  const loginPaths: string[] = [
    NavigationRoutes.ForgotPassword,
    NavigationRoutes.ResetPassword,
    NavigationRoutes.Login,
    NavigationRoutes.__RegisterRoutePrefix,
    NavigationRoutes.OtpVerification,
  ];
  // Remove all entries in login paths
  StackNavigation.popWhile(
    (entry) => loginPaths.some((path) => entry.pathname.startsWith(path)),
    {
      pathname: NavigationRoutes.MySim,
      query: {},
    },
  );
}
