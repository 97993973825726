import { NavigationRoutes } from 'common/routes';
import { TextLink } from 'components/elements/button';
import Text from 'components/elements/text';
import useKurosimNavigation from 'hooks/use-kurosim-navigation';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface RedirectionAuthComponentProps {
  type: 'login' | 'register';
}

export default function RedirectionAuthComponent(
  props: RedirectionAuthComponentProps,
) {
  const { type } = props;
  const { t } = useTranslation();
  const { replace } = useKurosimNavigation();
  const information = React.useMemo(() => {
    if (type === 'login') {
      return {
        label: t('auth:dont_have_an_account'),
        textLinkLabel: t('common:sign_up'),
        onClick: () => {
          replace(NavigationRoutes.Register, {
            query: undefined,
          });
        },
      };
    }

    return {
      label: t('auth:have_an_account'),
      textLinkLabel: t('auth:login'),
      onClick: () => replace(NavigationRoutes.Login),
    };
  }, [replace, t, type]);

  return (
    <Text ta="center" textVariant="body1Regular" textColor="foregroundTertiary">
      {information.label}{' '}
      <TextLink
        textProps={{
          textVariant: 'body1Semibold',
          textColor: 'foregroundLinks',
        }}
        onClick={information.onClick}
      >
        {information.textLinkLabel}
      </TextLink>
    </Text>
  );
}
