import { clearSessionData, useOauthSession } from 'common/auth';
import notification from 'common/helpers/notification';
import { Referral } from 'common/repositories/referral';
import { NavigationRoutes } from 'common/routes';
import useNativeBridge from 'common/routes/bridge';
import {
  BridgeMessageType,
  OauthResultType,
  OauthStatusEnum,
} from 'common/routes/bridge-types';
import { KurosimTimezone } from 'common/utils/date';
import { popOutOfLoginFlow } from 'modules/login/register/profile/utils';
import { signIn } from 'next-auth/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useDetectDevice from './use-detect-device';
import useKurosimNavigation from './use-kurosim-navigation';
import useOauthResumeFlow from './use-oauth-resume-flow';

export type ProviderType = 'google' | 'apple';

export default function useOauthController() {
  const { t } = useTranslation();
  const { refresh } = useKurosimNavigation();
  const [loadingGoogle, setLoadingGoogle] = React.useState(false);
  const [loadingApple, setLoadingApple] = React.useState(false);
  const onLoadingOauth = React.useCallback((provider: ProviderType) => {
    setLoadingApple(provider === 'apple');
    setLoadingGoogle(provider === 'google');
  }, []);
  const { isApple: isShowApple, isKurosimApp, isIos } = useDetectDevice();
  const resumeFlow = useOauthResumeFlow();

  const { onGetToken } = useOauthSession();
  const onLoginOauthNative = React.useCallback(
    async (bridgeResponse: OauthResultType) => {
      if (bridgeResponse.type === OauthStatusEnum.error) {
        notification.error({
          message: t(`error:${bridgeResponse.message}`),
        });
      } else {
        try {
          const timezone = KurosimTimezone.get();
          const result = await onGetToken({
            familyName: bridgeResponse.familyName,
            givenName: bridgeResponse.givenName,
            idToken: bridgeResponse.idToken,
            provider: bridgeResponse.provider,
            timezone,
          });

          notification.success({
            message: result.message,
          });
          //replacement
          popOutOfLoginFlow();
          const canContinue = await resumeFlow(result.data);
          if (canContinue) {
            refresh();
          }
        } catch (e) {
          notification.error({
            message: e.message,
          });
        }
      }
      setLoadingApple(false);
      setLoadingGoogle(false);
    },
    [onGetToken, refresh, resumeFlow, t],
  );

  const send = useNativeBridge({
    handlers: {
      [BridgeMessageType.GoogleLoginResponse]: (message) => {
        const result = message.data;
        onLoginOauthNative(result);
      },
      [BridgeMessageType.AppleLoginResponse]: (message) => {
        const result = message.data;
        onLoginOauthNative(result);
      },
    },
  });

  const onOauthWeb = React.useCallback(async (provider: ProviderType) => {
    await clearSessionData();
    const callbackUrl = Referral.get()
      ? `${NavigationRoutes.ReferralBind}?code=${Referral.get()}`
      : // NB: temporary solution after login from Oauth to store, because we need to redirect it to my esims page and it cause a problem after authorize
        '/store';
    signIn(provider, {
      callbackUrl,
      redirect: true,
    });
  }, []);

  const onSendAppleLoginRequest = React.useCallback(async () => {
    onLoadingOauth('apple');
    if (isKurosimApp) {
      send?.({
        type: BridgeMessageType.AppleLoginRequest,
        data: null,
      });
    } else {
      onOauthWeb('apple');
    }
  }, [isKurosimApp, onLoadingOauth, onOauthWeb, send]);

  const onSendGoogleLoginRequest = React.useCallback(async () => {
    onLoadingOauth('google');
    if (isKurosimApp) {
      send?.({
        type: BridgeMessageType.GoogleLoginRequest,
        data: null,
      });
    } else {
      onOauthWeb('google');
    }
  }, [isKurosimApp, onLoadingOauth, onOauthWeb, send]);

  React.useEffect(() => {
    if (!isIos) return; // only for ios only
    // commonly apple use bottom sheet for oauth sign in
    if (loadingApple) {
      const timer = setTimeout(() => {
        setLoadingApple(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
    // commonly google use redirection for oauth sign in
    if (loadingGoogle) {
      const timer = setTimeout(() => {
        setLoadingGoogle(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isIos, loadingApple, loadingGoogle]);

  return {
    onSendGoogleLoginRequest,
    onSendAppleLoginRequest,
    isShowApple,
    loadingGoogle,
    loadingApple,
  };
}
