import { useCheckEmail } from 'api-hooks/auth';
import React from 'react';

export default function useKurosimCheckEmail() {
  const { mutateAsync: checkEmailAsync } = useCheckEmail();

  const onCheckEmail = React.useCallback(
    async (email: string, captcha: string) => {
      const checkEmailResult = await checkEmailAsync({
        email,
        cf_turnstile_response: captcha,
      });

      const inUse = checkEmailResult.data.inUse;
      const provider = checkEmailResult.data.provider;

      return {
        inUse,
        provider,
      };
    },
    [checkEmailAsync],
  );

  return onCheckEmail;
}
