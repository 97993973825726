import { AppleSVG, GoogleSVG } from 'assets/providers';
import classNames from 'classnames';
import Separator from 'components/common/separator';
import Button from 'components/elements/button';
import Text from 'components/elements/text';
import useOauthController from 'hooks/use-oauth-controller';
import React from 'react';
import { useTranslation } from 'react-i18next';
import structuralStyles from 'styles/layout.css';

export default function SSOComponent() {
  const { t } = useTranslation();
  const {
    isShowApple,
    loadingApple,
    loadingGoogle,
    onSendAppleLoginRequest,
    onSendGoogleLoginRequest,
  } = useOauthController();

  // write case when google is disabled
  const googleDisable = React.useMemo(() => {
    return false;
  }, []);

  // write case when apple is disabled
  const appleDisable = React.useMemo(() => {
    return false;
  }, []);

  const ssos = [
    {
      onClick: onSendGoogleLoginRequest,
      loading: loadingGoogle,
      leftSection: googleDisable ? undefined : (
        <GoogleSVG width={20} height={20} />
      ),
      text: 'Google',
      disabled: googleDisable,
    },
    ...(isShowApple
      ? [
          {
            onClick: onSendAppleLoginRequest,
            loading: loadingApple,
            leftSection: appleDisable ? undefined : (
              <AppleSVG width={20} height={20} />
            ),
            text: 'Apple',
            disabled: appleDisable,
          },
        ]
      : []),
  ];
  return (
    <>
      <div
        className={classNames(
          structuralStyles.flexbox({
            direction: 'row',
            gap: 16,
            justify: 'center',
          }),
          structuralStyles.fill({ width: true }),
        )}
      >
        {ssos.map((sso) => {
          return (
            <Button
              key={sso.text}
              variant={{
                variant: 'secondary',
              }}
              leftSection={sso.leftSection}
              loading={sso.loading}
              onClick={sso.onClick}
              fullWidth
              disabled={sso.disabled}
            >
              {sso.text}
            </Button>
          );
        })}
      </div>
      <Separator gap={16} />
      <div className="separator">
        <Text textVariant="body2Regular" textColor="foregroundTertiary">
          {t('profile:or_continue_with')}
        </Text>
      </div>
    </>
  );
}
