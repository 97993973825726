import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile';
import config from 'common/config';
import { useIsSmallScreen } from 'hooks/use-is-small-screen';
import * as React from 'react';

interface TurnstileCaptchaProps {
  onSuccess: (token: string) => void;
}

const TurnstileCaptcha = React.forwardRef<
  TurnstileInstance,
  TurnstileCaptchaProps
>((props, ref) => {
  const { onSuccess } = props;
  const small = useIsSmallScreen();

  const onReset = React.useCallback(() => {
    (ref as any)?.current?.reset();
  }, [ref]);

  return (
    <Turnstile
      ref={ref}
      style={{ marginBottom: 32 }}
      options={{ theme: 'light', size: small ? 'compact' : 'normal' }}
      siteKey={config.cloudflareSiteKey}
      onSuccess={onSuccess}
      onError={(errorCode) => {
        if (errorCode.startsWith('300') || errorCode.startsWith('600')) {
          setTimeout(onReset, 3000);
        } else {
          onReset();
        }
      }}
      onExpire={() => {
        onReset();
      }}
    />
  );
});

export default TurnstileCaptcha;
