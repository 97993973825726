import { NavigationSiteRoutes } from 'common/routes';
import Separator from 'components/common/separator';
import { TextLink } from 'components/elements/button';
import Text from 'components/elements/text';
import { useNavigateSite } from 'hooks/use-navigate-site';
import { useTranslation } from 'react-i18next';
import structuralStyles from 'styles/layout.css';

export default function AgreementComponent() {
  const { t } = useTranslation();
  const navigateSite = useNavigateSite();
  return (
    <>
      <Text
        textVariant="body2Regular"
        ta="center"
        textColor="foregroundTertiary"
      >
        {t('common:kurosim_agreement')}
      </Text>
      <div className={structuralStyles.flexbox({ justify: 'center' })}>
        <TextLink
          textProps={{
            textVariant: 'linkSmall',
            textColor: 'foregroundLinks',
          }}
          onClick={() => {
            navigateSite(NavigationSiteRoutes.PrivacyPolicy);
          }}
        >
          {t('common:privacy_policy')}
        </TextLink>
        <Text textVariant="body2Regular" pt={3} textColor="foregroundTertiary">
          &nbsp;{t('common:and')}&nbsp;
        </Text>
        <TextLink
          textProps={{
            textVariant: 'linkSmall',
            textColor: 'foregroundLinks',
          }}
          onClick={() => {
            navigateSite(NavigationSiteRoutes.TermsOfService);
          }}
        >
          {t('common:terms_of_service')}
        </TextLink>
      </div>
      <Separator gap={24} />
    </>
  );
}
