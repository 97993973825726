import { useAuth } from 'common/auth';
import { CustomValue } from 'common/repositories/customization';
import { NavigationRoutes } from 'common/routes';
import { isProtectedRoutes } from 'components/common/private-routes';
import { AppHeader } from 'components/widgets/header';
import { AppLayout } from 'components/widgets/layout';
import { useEffectLimits } from 'hooks/use-effect-derivatives';
import useKurosimNavigation, {
  StackNavigation,
} from 'hooks/use-kurosim-navigation';
import { useDelayedRedirect } from 'hooks/use-kurosim-navigation/navigator';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function useRedirectToStoreIfAuthenticated() {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();

  const canRunEffect = useEffectLimits({
    times: 1,
    enabled: isAuthenticated,
  });

  const redirect = useDelayedRedirect({
    message: t('common:redirecting_to_extra', { extra: t('home:my_sim') }),
    navigate({ replace }) {
      replace(NavigationRoutes.MySim);
    },
  });

  React.useEffect(() => {
    if (!canRunEffect()) {
      return;
    }
    redirect();
  }, [canRunEffect, isAuthenticated, redirect]);
}

export default function LoginLayout(props: React.PropsWithChildren) {
  const { refresh } = useKurosimNavigation();
  const { isAuthenticated } = useAuth();
  // useOnboardingOnFirstVisit has removed because it makes side effect for redirection to previous page when logged

  useRedirectToStoreIfAuthenticated();

  return (
    <AppLayout
      customization={{
        root: {
          style: CustomValue.combine({
            display: 'block',
            overflow: 'auto',
          }),
        },
      }}
      Header={
        <AppHeader
          back
          customization={{
            root: {
              noBorder: true,
            },
          }}
          allowedDefaultActions={[]}
          onGoBack={() => {
            StackNavigation.pop();
            if (!isAuthenticated) {
              StackNavigation.popWhile((x) => isProtectedRoutes(x.pathname));
            }

            refresh();
          }}
        />
      }
    >
      {props.children}
    </AppLayout>
  );
}

export const LoginNextPhaseContext = React.createContext<() => void>(() => {});
