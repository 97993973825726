import { TurnstileInstance } from '@marsidev/react-turnstile';
import TurnstileCaptcha from 'components/widgets/layout/turnstile/recaptcha';
import React from 'react';

export default function useTurnstile() {
  const [captcha, setCaptcha] = React.useState('');
  const turnstileRef = React.useRef<TurnstileInstance>(null);
  const onResetCaptcha = React.useCallback(() => {
    turnstileRef?.current?.reset();
    setCaptcha('');
  }, []);

  const turnstileComponent = (
    <TurnstileCaptcha onSuccess={setCaptcha} ref={turnstileRef} />
  );

  return {
    turnstileRef,
    onResetCaptcha,
    setCaptcha,
    captcha,
    turnstileComponent,
  };
}
